<template>
  <div id="outputList">
    <el-dialog
      :title="outputListFormTitle"
      width="800px"
      :visible.sync="outputListDialogVisible"
      :close-on-click-modal="false"
      @close="outputListDialogClose"
    >
      <el-form
        ref="outputListFormRef"
        :model="outputListForm"
        :rules="outputListFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="新产品名称" prop="newProductName">
              <el-input v-model="outputListForm.newProductName" placeholder="请输入新产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号/规格" prop="spec">
              <el-input v-model="outputListForm.spec" placeholder="请输入型号/规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设计开发输出清单">
              <el-scrollbar style="height: 95.2px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="outputListForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="相关资料数量" prop="fileQuantity">
              <el-input v-model="outputListForm.fileQuantity" placeholder="请输入相关资料数量" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="编制人" prop="editor">
              <el-input v-model="outputListForm.editor" placeholder="请输入编制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制日期" prop="editTime">
              <el-date-picker v-model="outputListForm.editTime" placeholder="请选择编制日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核人" prop="auditor">
              <el-input v-model="outputListForm.auditor" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核日期" prop="auditTime">
              <el-date-picker v-model="outputListForm.auditTime" placeholder="请选择审核日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="outputListForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approvalTime">
              <el-date-picker v-model="outputListForm.approvalTime" placeholder="请选择批准日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="outputListDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="outputListFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="新产品名称">
        <el-input v-model="searchForm.newProductName" placeholder="请输入新产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="outputListPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="newProductName" label="新产品名称" />
      <el-table-column prop="spec" label="型号/规格" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="fileQuantity" label="相关资料数量" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editTime">{{ scope.row.editTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="auditor" label="审核人" />
      <el-table-column label="审核日期">
        <template slot-scope="scope">
          <span v-if="scope.row.auditTime">{{ scope.row.auditTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalTime">{{ scope.row.approvalTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="outputListPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addOutputList, deleteOutputList, updateOutputList, selectOutputListInfo, selectOutputListList } from '@/api/technology/outputList'
import { upload } from '@/api/main/file'

export default {
  data () {
    return {
      outputListDialogVisible: false,
      outputListFormTitle: '',
      outputListForm: {
        id: '',
        newProductName: '',
        spec: '',
        remarks: '',
        fileQuantity: '',
        editor: '',
        editTime: '',
        auditor: '',
        auditTime: '',
        approver: '',
        approvalTime: '',
        outputUrl: '',
        outputUrlName: ''
      },
      outputListFormRules: {
        newProductName: [{ required: true, message: '新产品名称不能为空', trigger: ['blur', 'change']}]
      },
      outputListPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        newProductName: ''
      },
      fileList: []
    }
  },
  created () {
    selectOutputListList(this.searchForm).then(res => {
      this.outputListPage = res
    })
  },
  methods: {
    outputListDialogClose () {
      this.$refs.outputListFormRef.resetFields()
      this.$refs.uploadRef.clearFiles()
      this.fileList = []
    },
    outputListFormSubmit () {
      if (this.outputListFormTitle === '设计开发输出清单详情') {
        this.outputListDialogVisible = false
        return
      }
      this.$refs.outputListFormRef.validate(async valid => {
        if (valid) {
          this.outputListForm.outputUrl = this.fileList.map(file => file.path).join(',')
          this.outputListForm.outputUrlName = this.fileList.map(file => file.name).join(',')
          if (this.outputListFormTitle === '新增设计开发输出清单') {
            await addOutputList(this.outputListForm)
          } else if (this.outputListFormTitle === '修改设计开发输出清单') {
            await updateOutputList(this.outputListForm)
          }
          this.outputListPage = await selectOutputListList(this.searchForm)
          this.outputListDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.outputListFormTitle = '新增设计开发输出清单'
      this.outputListDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteOutputList(row.id)
        if (this.outputListPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.outputListPage = await selectOutputListList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.outputListFormTitle = '修改设计开发输出清单'
      this.outputListDialogVisible = true
      this.selectOutputListInfoById(row.id)
    },
    handleInfo (index, row) {
      this.outputListFormTitle = '设计开发输出清单详情'
      this.outputListDialogVisible = true
      this.selectOutputListInfoById(row.id)
    },
    selectOutputListInfoById (id) {
      selectOutputListInfo(id).then(res => {
        this.outputListForm.id = res.id
        this.outputListForm.newProductName = res.newProductName
        this.outputListForm.spec = res.spec
        this.outputListForm.remarks = res.remarks
        this.outputListForm.fileQuantity = res.fileQuantity
        this.outputListForm.editor = res.editor
        this.outputListForm.editTime = res.editTime
        this.outputListForm.auditor = res.auditor
        this.outputListForm.auditTime = res.auditTime
        this.outputListForm.approver = res.approver
        this.outputListForm.approvalTime = res.approvalTime
        if (res.outputUrl) {
          const outputUrl = res.outputUrl.split(',')
          const outputUrlName = res.outputUrlName.split(',')
          this.fileList = []
          for (let i = 0; i < outputUrl.length; i++) {
            this.fileList.push({
              name: outputUrlName[i],
              path: outputUrl[i]
            })
          }
        }
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectOutputListList(this.searchForm).then(res => {
        this.outputListPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectOutputListList(this.searchForm).then(res => {
        this.outputListPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectOutputListList(this.searchForm).then(res => {
        this.outputListPage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
#outputList .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
