import axios from '@/common/axios'
import qs from 'qs'

export function addOutputList (data) {
  return axios({
    method: 'post',
    url: '/technology/output/add',
    data: qs.stringify(data)
  })
}

export function deleteOutputList (id) {
  return axios({
    method: 'delete',
    url: '/technology/output/delete/' + id
  })
}

export function updateOutputList (data) {
  return axios({
    method: 'put',
    url: '/technology/output/update',
    data: qs.stringify(data)
  })
}

export function selectOutputListInfo (id) {
  return axios({
    method: 'get',
    url: '/technology/output/info/' + id
  })
}

export function selectOutputListList (query) {
  return axios({
    method: 'get',
    url: '/technology/output/list',
    params: query
  })
}
